import { calculateDeviceCategory } from '@/utils/ui.utils';
import WebAppUtils from '@/utils/webApp.utils';
import commonUtils from '@/utils/common.utils';
import lazyLoadResource from '@/utils/lazyLoad.utils';

const ivwConsentDomainDesktop = {
  bunte: 'data-9b3233a086.bunte.de',
  burdaHealth: 'data-9dc3fcd9b4.netdoktor.de',
};

const ivwConsentDomainMobile = {
  bunte: 'data-574debde52.bunte.de',
  burdaHealth: 'data-fbb8842b89.netdoktor.de',
};

const isHealthContent = window.location.pathname.startsWith('/health');

const isIvwBlocked = window.utag_data?.pageLevel1 !== 'health';

export const track = (ivwCpParam?: string): void => {
  if (isIvwBlocked) return;
  if (commonUtils.dataProtectionPage()) return;
  if (window.iom) {
    let ivwCp = ivwCpParam;
    if (ivwCpParam === undefined) {
      if (window.analyticsData && window.analyticsData.b_ivwCp) {
        ivwCp = window.analyticsData.b_ivwCp;
      } else {
        ivwCp = 'verschiedenes';
      }
    }
    const deviceCategory = calculateDeviceCategory();

    let st = deviceCategory === 'mobile' ? (isHealthContent ? 'mobnetdo' : 'mobbunte') : isHealthContent ? 'netdok' : 'bunteton';
    let sv = deviceCategory === 'mobile' ? 'mo' : 'i2';

    // Use partner IVW configuration in case its enabled
    if (window.ivwOverride) {
      st = (deviceCategory === 'mobile' ? window.ivwOverride.ivwSTMEW : window.ivwOverride.ivwST) || st;
      sv = (deviceCategory === 'mobile' ? window.ivwOverride.ivwSVMEW : window.ivwOverride.ivwSV) || sv;

      if (ivwCpParam === 'video') {
        ivwCp = (deviceCategory === 'mobile' ? window.ivwOverride.ivwCPVideoMEW : window.ivwOverride.ivwCPVideo) || ivwCp;
      } else {
        ivwCp = (deviceCategory === 'mobile' ? window.ivwOverride.ivwCPNewsMEW : window.ivwOverride.ivwCPNews) || ivwCp;
      }
    }

    if (WebAppUtils.isApp()) {
      st = WebAppUtils.getAppPlatform() === 'ios' ? 'appbunte' : 'aadbunte';
      sv = 'mo';
    }

    const purSuffix = localStorage.getItem('user:plan') === 'pur-monatlich' ? '_pur' : '';

    const iamData = {
      mg: 'yes',
      st: st,
      cp: `${ivwCp}${purSuffix}`,
      sv: sv,
      sc: 'yes',
    };

    window.iam_fadeout_flash = false;
    window.iam_fadeout_iframe = false;
    window.iam_fadeout_form = false;
    window.iam_position_top = 150;
    window.iam_position_left = 100;
    window.iam_zindex = 10000000000;

    console.log('IVW: ', iamData);
    window.iom.c(iamData, 1);
  } else {
    setTimeout(track, 20);
  }
};

export const loadIVWMeasureManager = async (): Promise<void> => {
  if (isIvwBlocked) return;
  const deviceCategory = calculateDeviceCategory();
  const { st, dn } =
    deviceCategory === 'mobile'
      ? {
          st: isHealthContent ? 'mobnetdo' : 'mobbunte',
          dn: isHealthContent ? ivwConsentDomainMobile.burdaHealth : ivwConsentDomainMobile.bunte,
        }
      : {
          st: isHealthContent ? 'netdok' : 'bunteton',
          dn: isHealthContent ? ivwConsentDomainDesktop.burdaHealth : ivwConsentDomainDesktop.bunte,
        };
  await Promise.all([
    lazyLoadResource(`//${dn}/iomm/latest/bootstrap/stub.js`),
    lazyLoadResource(`//${dn}/iomm/latest/manager/base/es5/bundle.js`),
  ]);
  window.IOMm('configure', { st, dn, mh: 5 });
  console.log('IVW2-configure: ', { st, dn });
};

export const pageView = (ivwCpParam?: string): void => {
  if (isIvwBlocked) return;
  if (commonUtils.dataProtectionPage()) return;
  if (window.IOMm) {
    let ivwCp = ivwCpParam;
    if (ivwCpParam === undefined) {
      if (window.analyticsData && window.analyticsData.b_ivwCp) {
        ivwCp = window.analyticsData.b_ivwCp;
      } else if (commonUtils.ivwMMcpException()) {
        ivwCp = 'Angebotsinformation';
      } else {
        ivwCp = 'verschiedenes';
      }
    }
    const deviceCategory = calculateDeviceCategory();

    // Use partner IVW configuration in case its enabled
    if (window.ivwOverride) {
      if (ivwCpParam === 'video') {
        ivwCp = (deviceCategory === 'mobile' ? window.ivwOverride.ivwCPVideoMEW : window.ivwOverride.ivwCPVideo) || ivwCp;
      } else {
        ivwCp = (deviceCategory === 'mobile' ? window.ivwOverride.ivwCPNewsMEW : window.ivwOverride.ivwCPNews) || ivwCp;
      }
    }

    const purSuffix = localStorage.getItem('user:plan') === 'pur-monatlich' ? '_pur' : '';

    window.IOMm('pageview', { cp: ivwCp + purSuffix });
    console.log('IVW2-pageview: ', { cp: ivwCp + purSuffix });
  } else {
    setTimeout(pageView, 20);
  }
};
